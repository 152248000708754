<template>
    <section class="flex justify-center w-[100vw]" id="cursos">


        <div class=" bg-cover  mt-2 flex  w-[100vw] justify-center "
            style="background-color: #222E42; background-image: url('https://dettmann32.github.io/imagens-for-supershow-web-site/imagens/texturaFk%20WHITE.png');">

            <div class=" absolute text-center">
                <h1 class="text-3xl text-red-700 " style="z-index: 1;">
                    CONHEÇA NOSSOS CURSOS

                </h1>
            </div>
            <div class="mt-10">
                <div class="grid grid-col-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 mt-5 justify-items-center">
                    <router-link to="/operadordecaixa">
                        <div class="flex  items-center justify-center m-10 ">
                            <div class="mx-auto px-5">
                                <div
                                    class="max-w-xs cursor-pointer rounded-lg bg-white p-2 shadow duration-150 hover:scale-105 hover:shadow-md">
                                    <img class="w-full rounded-lg object-cover object-center"
                                        src="../../assets/images/img-cursos/op de caixa 3.png" alt="product" />
                                    <p class="my-4 pl-4 font-bold text-gray-500">OPERADOR DE CAIXA NO SUPERMERCADO</p>
                                    <p class="mb-4 ml-4 text-xl font-semibold text-gray-800">Para aumentar as vendas</p>
                                </div>
                            </div>
                        </div>
                    </router-link>

                    <router-link to="/repositor">
                        <div class="flex  items-center justify-center m-10 ">
                            <div class="mx-auto px-5">
                                <div
                                    class="max-w-xs cursor-pointer rounded-lg bg-white p-2 shadow duration-150 hover:scale-105 hover:shadow-md">
                                    <img class="w-full rounded-lg object-cover object-center"
                                        src="../../assets/images/img-cursos/repositora.png" alt="product" />
                                    <p class="my-4 pl-4 font-bold text-gray-500">REPOSITOR DE SUPERMERCADO</p>
                                    <p class="mb-4 ml-4 text-xl font-semibold text-gray-800">Para aumentar as vendas</p>
                                </div>
                            </div>
                        </div>
                    </router-link>

                    <router-link to="/padaria">
                        <div class="flex  items-center justify-center m-10 ">
                            <div class="mx-auto px-5">
                                <div
                                    class="max-w-xs cursor-pointer rounded-lg bg-white p-2 shadow duration-150 hover:scale-105 hover:shadow-md">
                                    <img class="w-full rounded-lg object-cover object-center"
                                        src="../../assets/images/img-cursos/padeira.jpg" alt="product" />
                                    <p class="my-4 pl-4 font-bold text-gray-500">PADARIA NO SUPERMERCADO</p>
                                    <p class="mb-4 ml-4 text-xl font-semibold text-gray-800">Para aumentar as vendas</p>
                                </div>
                            </div>
                        </div>
                    </router-link>

                    <router-link to="/açougue">

                        <div class="flex  items-center justify-center m-10 ">
                            <div class="mx-auto px-5">
                                <div
                                    class="max-w-xs cursor-pointer rounded-lg bg-white p-2 shadow duration-150 hover:scale-105 hover:shadow-md">
                                    <img class="w-full rounded-lg object-cover object-center"
                                        src="../../assets/images/img-cursos/açougueiro 2.jpg" alt="product" />
                                    <p class="my-4 pl-4 font-bold text-gray-500">AÇOUGUE NO SUPER MERCADO</p>
                                    <p class="mb-4 ml-4 text-xl font-semibold text-gray-800">Para aumentar as vendas</p>
                                </div>
                            </div>
                        </div>
                    </router-link>

                    <router-link to="/hortfruti">

                        <div class="flex  items-center justify-center m-10 ">
                            <div class="mx-auto px-5">
                                <div
                                    class="max-w-xs cursor-pointer rounded-lg bg-white p-2 shadow duration-150 hover:scale-105 hover:shadow-md">
                                    <img class="w-full rounded-lg object-cover object-center"
                                        src="../../assets/images/img-cursos/REPOSITOR DE HORTT.png" alt="product" />
                                    <p class="my-4 pl-4 font-bold text-gray-500">HORTIFRÚTI NO SUPERMERCADO</p>
                                    <p class="mb-4 ml-4 text-xl font-semibold text-gray-800">Para aumentar as vendas</p>
                                </div>
                            </div>
                        </div>
                    </router-link>

                    <router-link to="/embalador">
                        <div class="flex  items-center justify-center m-10 ">
                            <div class="mx-auto px-5">
                                <div
                                    class="max-w-xs cursor-pointer rounded-lg bg-white p-2 shadow duration-150 hover:scale-105 hover:shadow-md">
                                    <img class="w-full rounded-lg object-cover object-center"
                                        src="../../assets/images/img-cursos/embalador 2.png" alt="product" />
                                    <p class="my-4 pl-4 font-bold text-gray-500">EMBALADOR NO SUPERMERCADO</p>
                                    <p class="mb-4 ml-4 text-xl font-semibold text-gray-800">Para aumentar as vendas</p>
                                </div>
                            </div>
                        </div>

                    </router-link>

                    <router-link to="/laticinios">

                        <div class="flex  items-center justify-center m-10 ">
                            <div class="mx-auto px-5">
                                <div
                                    class="max-w-xs cursor-pointer rounded-lg bg-white p-2 shadow duration-150 hover:scale-105 hover:shadow-md">
                                    <img class="w-full rounded-lg object-cover object-center"
                                        src="../../assets/images/img-cursos/repositora de lacticinios.png" alt="product" />
                                    <p class="my-4 pl-4 font-bold text-gray-500">FRIAMBERIA E LATICÍNIOS NO SUPERMERCADO</p>
                                    <p class="mb-4 ml-4 text-xl font-semibold text-gray-800">Para aumentar as vendas</p>
                                </div>
                            </div>
                        </div>

                    </router-link>

                </div>





                <!-- background-image: url('https://dettmann32.github.io/imagens-for-supershow-web-site/imagens/reposicao2.png'); -->






            </div>

        </div>

    </section>
</template>


<script setup>




</script>