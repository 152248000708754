<template>
    <footer class="w-[100vw] bg-gray-900 flex flex-col p-5 mt-2" id="contatos">
        <div class="flex justify-center items-end h-[100%]">
            <div class="text-white flex flex-col justify-center">

                <a href="mailto:contato@supershowsupermercados.com.br" target="_blank" ref="external" class="text-center">contato@supershowsupermercados.com.br</a>

                <ul class="flex justify-around my-4">
                    <li><a href="https://www.instagram.com/supershowsupermercados/" target="_blank"> <img src="../assets/images/instagram.png" alt="Logo Instagram"></a></li>
                    <li><a href="https://wa.me/5527998421660" target="_blank"><img src="../assets/images/whatsapp.png" alt="Logo Whatsapp" ></a></li>
                    <li><a href="https://www.facebook.com/supershowsupermercado/?locale=pt_BR" target="_blank"><img src="../assets/images/facebook.png" alt="Logo FaceBook"></a></li>
                </ul>

                <p class="text-center">©2023 por, MultiShow Santa Maria de Jetibá</p>
            </div>
        </div>
    </footer>
</template>


<script setup>

</script>




<style scoped>

img{
    width: 35px;
    height: 35px;
}

a:hover{
    color: rgb(226, 33, 33);
    text-decoration: underline;
}


</style>