<template>
    <div class="w-[100vw] h-[400px] no bg-no-repeat bg-cover bg-fixed" style="background-image: url('https://raw.githubusercontent.com/dettmann32/imagens-for-supershow-web-site/main/imagens/reposicao.webp');">



    </div>
</template>

<script setup>



</script>